import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Inject, Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { millisecondsToMinutes } from 'date-fns';
import { isNil } from 'lodash-es';
import {
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  finalize,
  firstValueFrom,
  map,
  noop,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { Ad } from '@stsm/advertisement/models/ad';
import { AdsStatusService } from '@stsm/advertisement/services/ads-status.service';
import { AdsStore } from '@stsm/advertisement/services/ads-store.service';
import { AuthStore } from '@stsm/auth/data/auth-store.service';
import { AppActiveService } from '@stsm/global/composite/services/app-active.service';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { RouterStoreFacade } from '@stsm/shared/router-store/router-store-facade.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { getDeepestActivatedRoute } from '@stsm/shared/util/router.util';
import { intervalWithActiveCheck, shareReplayRefCount, tapOnce } from '@stsm/shared/util/rxjs.util';
import { ViewRef } from '@stsm/ui-components/dialogs/models/view-ref';

import { AdPopupComponent } from './ad-popup.component';
import { AD_POPUP_FREQUENCY, AD_POPUP_INITIAL_DELAY } from './ad-popup.constants';
import { AD_POPUP_AD } from './ad-popup-ad.token';

export const ENABLE_AD_POPUP_ROUTE_KEY = 'enableAdPopup';

enum AdPopupDebugMessage {
  ENABLED = 'Ad popups have been enabled',
  DISABLED = 'Ad popups have been disabled',
  INTERVAL_STARTED = 'Ad popup interval has started',
  INTERVAL_STOPPED = 'Ad popup interval has stopped',
}

@Injectable()
export class AdPopupService {
  private _currentAdPopupComponent: AdPopupComponent | undefined;

  private readonly _intervalDuration$: Subject<number> = new Subject<number>();

  private _isEnabledForAllRoutes: boolean = false;

  private readonly _isEnabled$: Observable<boolean> = this._routerStoreFacade.navigationEnd$.pipe(
    switchMap(() => {
      if (this._isEnabledForAllRoutes) {
        return of(true);
      }

      return getDeepestActivatedRoute(this._activatedRoute).data.pipe(
        map((data: Data) => {
          return Boolean(data[ENABLE_AD_POPUP_ROUTE_KEY]);
        }),
      );
    }),
    distinctUntilChanged(),
    shareReplayRefCount(1),
  );

  // We don't want to provide an AdsStore in root, so we just create a new instance
  private readonly _adsStore: AdsStore = new AdsStore();

  constructor(
    private readonly _overlay: Overlay,
    private readonly _loggerService: LoggerService,
    private readonly _appActiveService: AppActiveService,
    private readonly _authStore: AuthStore,
    private readonly _adsStatusService: AdsStatusService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _routerStoreFacade: RouterStoreFacade,
    @Inject(ENVIRONMENT) private readonly _environment: EnvironmentBase,
  ) {
    this._adsStore.init({ shouldAutoRefreshAd: false, source: 'popup' });

    combineLatest([
      of(this._environment.CONTENT_CREATORS),
      this._adsStatusService.adsDisabled$,
      this._authStore.isUserLoggedIn$,
      this._isEnabled$,
    ])
      .pipe(
        map(
          ([isContentCreatorPlatform, areAdsDisabled, isUserLoggedIn, isEnabled]: [boolean, boolean, boolean, boolean]) =>
            !isContentCreatorPlatform && !areAdsDisabled && isUserLoggedIn && isEnabled,
        ),
        distinctUntilChanged(),
        switchMap((isEnabled: boolean) => {
          this._log(isEnabled ? AdPopupDebugMessage.ENABLED : AdPopupDebugMessage.DISABLED);

          if (!isEnabled) {
            return EMPTY;
          }

          return this._intervalDuration$.pipe(
            startWith(AD_POPUP_INITIAL_DELAY),
            distinctUntilChanged(),
            switchMap((duration: number) => {
              this._log(AdPopupDebugMessage.INTERVAL_STARTED, duration);

              return intervalWithActiveCheck(duration, this._appActiveService.isAppActive$).pipe(
                switchMap(() => this._adsStore.currentAd$.pipe(take(1))),
                tap((ad: Ad | undefined) => {
                  if (!isNil(ad)) {
                    void this._showPopup(ad);
                    this._adsStore.onDidShowAd();
                  }
                }),
                tapOnce(() => this._intervalDuration$.next(AD_POPUP_FREQUENCY)),
                finalize(() => this._log(AdPopupDebugMessage.INTERVAL_STOPPED, duration)),
              );
            }),
          );
        }),
      )
      .subscribe();
  }

  init(): void {
    noop();
  }

  enableForAllRoutes(): void {
    this._isEnabledForAllRoutes = true;
  }

  private async _showPopup(advertisement: Ad): Promise<void> {
    this._loggerService.debug('AdPopupService._showPopup', JSON.stringify(advertisement));

    if (!advertisement.campaignName) {
      return;
    }

    // close current ad popup if existing
    if (!isNil(this._currentAdPopupComponent)) {
      this._currentAdPopupComponent.hide('ad_popup_disappear');

      await firstValueFrom(this._currentAdPopupComponent.viewRef.afterClosed());
    }

    const overlayRef = this._overlay.create({
      positionStrategy: this._overlay.position().global().bottom().right(),
      hasBackdrop: false,
    });

    const viewRef = new ViewRef(overlayRef);
    const componentPortal = new ComponentPortal(
      AdPopupComponent,
      null,
      Injector.create({
        providers: [
          { provide: AD_POPUP_AD, useValue: advertisement },
          { provide: ViewRef, useValue: viewRef },
        ],
      }),
    );

    viewRef.afterClosed().subscribe(() => {
      this._currentAdPopupComponent = undefined;
    });

    const componentRef = overlayRef.attach(componentPortal);
    this._currentAdPopupComponent = componentRef.instance;
  }

  private _log(message: AdPopupDebugMessage, intervalDuration?: number): void {
    const messages: string[] = [message];
    intervalDuration && messages.push(`(${millisecondsToMinutes(intervalDuration)} minutes)`);
    this._loggerService.debug(...messages);
  }
}
