import { animate, state, style, transition, trigger } from '@angular/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, signal, WritableSignal } from '@angular/core';

import { ActionId, ActionsHub } from '@stsm/actions-hub';
import { Ad } from '@stsm/advertisement/models/ad';
import { AdLocation } from '@stsm/advertisement/models/ad-location';
import { AnalyticsBaseService } from '@stsm/analytics/global/services/analytics-base.service';
import { ANALYTICS_SERVICE } from '@stsm/analytics/global/services/analytics-service.token';
import { IconButtonComponent } from '@stsm/ui-components/button';
import { ViewRef } from '@stsm/ui-components/dialogs/models/view-ref';

import { AdvertisementComponent } from '../advertisement/advertisement.component';
import { DitchAdsComponent } from '../ditch-ads/ditch-ads.component';

import { AD_POPUP_AD } from './ad-popup-ad.token';

const ANIMATION_TIMER: number = 500;

@Component({
  selector: 'app-enigma-popup',
  templateUrl: './ad-popup.component.html',
  styleUrls: ['./ad-popup.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('hidden', style({ transform: 'translateX(100%)', opacity: 0 })),
      state('visible', style({ transform: 'translateX(0)', opacity: 1 })),
      transition('hidden => visible', [animate(ANIMATION_TIMER)]),
      transition('visible => hidden', [animate(ANIMATION_TIMER)]),
    ]),
  ],
  imports: [NgIf, OverlayModule, PortalModule, AdvertisementComponent, DitchAdsComponent, IconButtonComponent],
  host: {
    'data-testid': 'ad-popup',
    'data-cy': 'ad-popup',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdPopupComponent {
  protected showPopup: WritableSignal<boolean> = signal(false);

  protected readonly location: AdLocation = AdLocation.POPUP;

  constructor(
    @Inject(AD_POPUP_AD) public ad: Ad,
    readonly viewRef: ViewRef,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _actionsHub: ActionsHub,
  ) {}

  hide(action: 'ad_popup_close' | 'ad_popup_disappear'): void {
    this._analyticsService.trackEvent({ action });

    this._closePopup();
  }

  protected onMediaLoaded(): void {
    this.showPopup.set(true);
  }

  protected openPremium(): void {
    void this._actionsHub.triggerAction(ActionId.OPEN_PREMIUM_DIALOG, { source: 'ad_popup' });

    this._closePopup();
  }

  private _closePopup(): void {
    this.showPopup.set(false);

    setTimeout(() => this.viewRef.dismiss(), ANIMATION_TIMER);
  }
}
